document.addEventListener("turbolinks:load", function() {
  var button = document.getElementById('nav_button');
  var links = document.getElementById('nav_links');
  var hamburger = document.getElementById('nav_hamburger');
  var x = document.getElementById('nav_x');

  button.onclick = function() {
    [links, hamburger, x].forEach(function (item, index) {
      item.classList.toggle('hidden');
    });
  }
});
